import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/IntroLayout.js";
import SEO from 'components/Seo';
import { Link } from 'gatsby';
import { Flex } from 'CHAMELEON_REACT_HUB';
import ic_about from 'images/ic_about.svg';
import ic_react from 'images/ic_react.svg';
import ic_tokens from 'images/ic_tokens.svg';
import ic_touch from 'images/ic_touch.svg';
import openGraphImage from '../images/chameleon_banner.png';
import LinkCard from 'components/LinkCard';
import Banner from 'components/Banner';
import Container from 'components/Container';
import Why from 'components/Why';
import Brands from 'components/Brands';
import Updates from 'components/Updates';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="chameleon - A multi-branded design system" keywords={['chameleon', 'mediahuis', 'design', 'system', 'design-system']} ogImagePath={openGraphImage} mdxType="SEO" />

    <Banner mdxType="Banner" />
    <Container mdxType="Container">
  <Why mdxType="Why" />
  <Brands mdxType="Brands" />
  <Updates mdxType="Updates" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      